import Dropdown from "../../../../commons/dropdown/Dropdown";
import Flex from "../../../../commons/flex/Flex";
import Avatar from "../avatar/avatar";
import Find from "../filters/find/find";
import Menu from "../filters/menu/menu";
import Tabs from "../filters/tabs/tabs";
import useHeader from "./useHeader";

interface IHeader {
  t: any;
  bookClassClick: () => void;
  catalogClick: () => void;
  myBooksClick: () => void;
  onFindChange: (value: string) => {};
  findValue: string;
  avatar: string;
  onSelectChange: (index: string) => void;
}

export default function Header(props: IHeader) {
  const { data, isBrowser, isPortrait, move } = useHeader({
    t: props.t,
  });

  return (
    <>
      <Flex customClass={"header fixed"}>
        <Flex customClass={"column"}>
          <Flex customClass={"spaceBtw top"}>
            <a
              className={`logo${move ? " move" : ""}`}
              target="_blank"
              href={`${
                process.env[
                  `REACT_APP_TATUM_WEB_${process.env.REACT_APP_ENVIROMENT}`
                ]
              }`}
            ></a>
            <Flex customClass={`books${move ? " hide" : ""}`}>
              <div className="title">{props.t("books")}</div>
            </Flex>
            <Flex customClass={"gap10"}>
              <Find
                value={props.findValue}
                onFindChange={props.onFindChange}
                placeHolder={props.t("search_input")}
              />
              <Avatar t={props.t} move={move} path={props.avatar} />
            </Flex>
          </Flex>
          <Flex customClass={"spaceAround bg"}>
            <Flex customClass={"filter spaceEvenly border"}>
              {!isPortrait && (
                <Flex customClass={"gap10 menu"}>
                  <Menu text={props.t("filters_title")} />
                </Flex>
              )}
              <Flex>
                <Tabs
                  t={props.t}
                  bookClassClick={props.bookClassClick}
                  catalogClick={props.catalogClick}
                  myBooksClick={props.myBooksClick}
                />
              </Flex>
              {!isPortrait && (
                <Dropdown data={data} onSelectChange={props.onSelectChange} />
              )}
            </Flex>
          </Flex>

          {!isBrowser && isPortrait && (
            <Flex customClass={"filter spaceAround tablet"}>
              <Flex customClass={"gap10 menu"}>
                <Menu text={props.t("filters_title")} />
              </Flex>
              <Dropdown data={data} onSelectChange={props.onSelectChange} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
}
