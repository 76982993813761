import { useContext, useEffect, useRef, useState } from "react";
import useManager from "../../../../hooks/useManager";
import { PackagesMetada } from "../../../../types/IPackages";
import globalContext from "../../../../context/Context";
import classrooms from "../../../../api/services/classrooms";
import IClassRoom from "../../../../types/IClassRoom";
import packages from "../../../../api/services/packages";
import cases from "../../../../api/services/cases";
import IRequestCase from "../../../../types/IRequestCase";
import IAction from "../../../../types/IAction";
import useScroll from "../../../../hooks/useScroll";

export default function useCards(props: any) {
  const manager = useManager();
  const [packs, setPacks] = useState<PackagesMetada[]>([]);
  const { user } = useContext(globalContext);
  const [action, setAction] = useState<IAction>();
  const [resume, setResume] = useState<IAction>();
  const [pIdentifier, setPIdentifier] = useState<PackagesMetada>();
  const myRef = useRef<HTMLDivElement | null>(null);
  const myWrapper = useRef<HTMLDivElement | null>(null);

  const isLastVisible = useScroll(myRef.current);

  useEffect(() => {
    if (isLastVisible) {
      props.getMoreResults();
    }
    return () => {};
  }, [isLastVisible]);

  useEffect(() => {
    const it = props.packages.slice(0, props.showTo);
    setPacks(it);
    if (myWrapper.current && props.initial === props.showTo) {
      myWrapper.current.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
    return () => {};
  }, [props.packages, props.showTo]);

  async function onClickRead(item: PackagesMetada) {
    const urlEnv =
      process.env[`REACT_APP_PRODUCT_${process.env.REACT_APP_ENVIROMENT}`];
    const api =
      process.env[`REACT_APP_API_I2C_${process.env.REACT_APP_ENVIROMENT}`];
    const url = new URL(
      `${urlEnv}/${item.reader_identifier}/version_web/index.html`
    );
    url.searchParams.append("api_i2c", `${api}/`);
    url.searchParams.append(
      "package",
      `../../${item.package_identifier}/version_web`
    );
    url.searchParams.append("session_id", user.session_id);
    url.searchParams.append(
      "language",
      user.language ? user.language.toLowerCase() : "es"
    );

    window.open(decodeURIComponent(url.toString()));

    if (!item.userPackage) {
      sendUserPackage(item.guid);
      const pack = [...packs];
      pack.forEach((p) => {
        if (p.guid === item.guid) {
          p.userPackage = true;
        }
      });
      setPacks(pack);
    }
  }

  async function sendUserPackage(guid: string) {
    packages.post(guid, user.session_id).then((res) => {
      //console.log(res);
    });
  }

  async function onAddToMyClass(item: PackagesMetada) {
    setPIdentifier(item as PackagesMetada);
    manager.notify({
      msg: props.t("wait"),
      type: "warning",
      wait: true,
    });
    classrooms
      .get({ book: item.package_identifier, session: user.session_id })
      .then((res: any) => {
        manager.notify({ msg: "" });
        setAction({
          type: "classRoom",
          package: item,
          classRoom: res.data.data.classrooms as IClassRoom[],
        });
      })
      .catch((err) => {});
  }

  async function onClickAction(props: IAction) {
    if (props.type === "remove") {
      packages.del(props.package!.guid, user.session_id).then((res) => {
        const pack = [...packs];
        pack.forEach((p) => {
          if (p.guid === props.package?.guid) {
            p.delete = true;
          }
        });
        setPacks(pack);
      });
    } else {
      if (props.type === "resume") setResume(props);
      else setAction(props);
    }
  }

  async function onClosePopup() {
    setAction({} as IAction);
  }
  async function onCloseResume() {
    setResume({} as IAction);
  }

  async function onClickAccept() {
    const pack = [...packs];

    pack.forEach((p) => {
      if (p.guid === action?.package?.guid) {
        if (action.type === "vote") p.voted = true;
      }
    });

    if (action?.type === "classRoom") {
      const removeFrom = action.classRoom
        ?.filter((item) => !item.is_classroom_book)
        .map(({ guid }) => guid);
      const addTo = action.classRoom
        ?.filter((item) => item.is_classroom_book)
        .map(({ guid }) => guid);

      const params = {
        book: pIdentifier?.package_identifier,
        addTo: addTo,
        removeFrom: removeFrom,
      };

      manager.notify({
        msg: props.t("wait"),
        wait: true,
      });

      classrooms.put(JSON.stringify(params), user.session_id).then((res) => {
        if (res.status === 200) {
          setAction({} as IAction);
          action.package!.classBook = true;
          manager.notify({
            msg: props.t("addWithSuccess"),
            hideAfter: true,
          });
        }
      });
    }

    if (action?.type === "vote") {
      manager.notify({
        msg: props.t("wait"),
        wait: true,
      });

      const post: IRequestCase = {
        book: {
          guid: action?.package?.package_identifier || "",
          name: action?.package?.title || "",
          lang: user.language,
        },
        username: user.username,
      };
      cases.post(post, user.session_id).then(() => {
        manager.notify({
          msg: "",
        });
        setPacks(pack);
        setAction({} as IAction);
      });
    }
  }

  function popupType() {
    if (action!.type === "vote") {
      return "requestCase";
    }

    if (action!.type === "classRoom") {
      return "addBook";
    }

    return "book_abstract";
  }

  return {
    onClickRead,
    onClosePopup,
    onClickAccept,
    onAddToMyClass,
    packs,
    action,
    onClickAction,
    onCloseResume,
    ref: myRef,
    resume,
    myWrapper,
    popupType,
  };
}
