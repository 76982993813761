export const en = {
  accept: "Accept",
  welcome: "Bienvenido a una nueva forma de hacerlo aún mejor",
  how: "¿Cómo trabajamos?",
  work: "Nuestra empresa trabaja de forma interactiva. Clientes, analistas y diseñadores trabajan juntos para desarrollar productos. Los programadores, los diseñadores de diseño y el control de calidad hacen su magia para obtener los mejores resultados.",
  solutions: "Nuestras soluciones",
  for: "Nuestra empresa brinda soluciones para web, escritorio, iOS y Anrdroid.",
  phone: "+55 (48) 97400-9729",
  phoneWs:
    "phone=5548974009729&text=Hi,%20I am%20interested%20in%20your%20services",
  city: "Florianópolis-SC Brasil",
  email: "info@nimbrasil.com",
  surname: "Apellido/Empresa",
  name: "Nombre",
  telephone: "Teléfono",
  message: "Mensaje",
  send: "Iniciar Sesión",
  sending: "Enviando mensaje...",
  sended: "Mensaje enviado",
  sendError: "Ocurrió un problema al enviar.",
  choose: "Seleccione su lenguaje preferido",
  urlSuscribe: "https://family.ta-tum.com/#subscribe",
  applyFilters: "Apply filters",
  legal: "Legal notice",
  privacy: "Privacy policy",
  cookies: "Cookies policy",
  edelvives: "Edelvives team",
  edelvivesLink: "https://www.edelvives.com/es/index",
};
