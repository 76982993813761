import { PackagesMetada } from "../../types/IPackages";
import Button from "../buttons/Button";
import Flex from "../flex/Flex";
import closeImg from "../../assets/imgs/close.png";
import defaultImg from "../../assets/imgs/default.png";
import Flag from "../flag/flag";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { nanoid } from "nanoid";

interface IResume {
  data?: PackagesMetada;
  isTeacher: boolean;
  isPremium: boolean;
  t: any;
  onClickRead: (item: PackagesMetada) => void;
  onClickRequestCase: (item: PackagesMetada) => void;
  onClickRemoveFromMyBooks: (item: PackagesMetada) => void;
  onClickAddToMyClass: (item: PackagesMetada) => void;
  onClose: () => void;
}

export default function Resume(props: IResume) {
  return (
    <Flex customClass={"resume"}>
      <Flex customClass={"middle"}>
        <Flex customClass={"wrapper column w100"}>
          <Flex customClass={"end"}>
            <Button onClick={props.onClose} icon={closeImg} />
          </Flex>
          <Flex customClass={"gap10"}>
            <Flex customClass={"column gap10 spaceBtw fit"}>
              <figure
                onClick={() => props.onClickRead(props.data as PackagesMetada)}
              >
                <img
                  loading="lazy"
                  src={props.data?.icon || defaultImg}
                  alt="image"
                />
              </figure>
              <Button
                custom={"orange"}
                onClick={() => props.onClickRead(props.data as PackagesMetada)}
                text={props.t("open")}
              />

              {props.isTeacher && (
                <Button
                  custom={"orange"}
                  onClick={() => {
                    props.onClickAddToMyClass(props.data as PackagesMetada);
                  }}
                  text={props.t("addClass")}
                />
              )}
              {props.isTeacher && props.isPremium && !props.data?.withCase && (
                <Button
                  custom={"transparent"}
                  disabled={props.data?.voted}
                  onClick={() =>
                    props.onClickRequestCase(props.data as PackagesMetada)
                  }
                  text={props.t(
                    props.data?.voted ? "requestCaseSuccess" : "requestCase"
                  )}
                  iconRight={props.data?.voted && <CheckIcon />}
                />
              )}
            </Flex>
            <Flex customClass={"column gap10 scroll"}>
              <Flag lang={props.data?.language_code || "word"} />
              <p className="title">{props.data?.title}</p>
              <Flex customClass={"column"}>
                {props.data?.creators.length &&
                  props.data?.creators.map((author) => {
                    return (
                      <p key={nanoid()} className="subTitle">
                        {author.name}
                      </p>
                    );
                  })}
              </Flex>
              <p className="description">{props.data?.description}</p>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
