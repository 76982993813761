import { nanoid } from "nanoid";
import Flex from "../../../../commons/flex/Flex";
import { PackagesMetada } from "../../../../types/IPackages";
import Card from "../card/card";
import NoResult from "../noResult";
import useCards from "./useCards";
import Popup from "../../../../commons/popups/Popup";
import ClassList from "../../../../commons/classList/ClassList";
import IClassRoom from "../../../../types/IClassRoom";
import Resume from "../../../../commons/resume/Resume";

interface ICards {
  t: any;
  isTeacher: boolean;
  isPremium: boolean;
  packages: any;
  showTo: number;
  getMoreResults: () => void;
  showMenu: boolean;
  initial: number;
}

export default function Cards(props: ICards) {
  const {
    onClosePopup,
    onClickAccept,
    onAddToMyClass,
    packs,
    action,
    onClickAction,
    onClickRead,
    onCloseResume,
    ref,
    resume,
    myWrapper,
    popupType,
  } = useCards(props);

  const cards = (
    <>
      {!packs.length && (
        <Flex>
          <NoResult text={props.t("no_results")} />
        </Flex>
      )}
      <Flex customClass={"gap10 wrap middle"}>
        {packs.length > 0 &&
          packs.map((item: PackagesMetada) => {
            return (
              !item.delete && (
                <Card
                  t={props.t}
                  key={item.guid}
                  title={item.title}
                  thumb={item.icon}
                  lang={item.language_code || "word"}
                  author={
                    (item.creators.length && item.creators[0].name) ||
                    "No author"
                  }
                  onClickCard={() => {
                    onClickAction({
                      type: "resume",
                      package: item,
                      classRoom: undefined,
                    });
                  }}
                  onClickRemoveFromMyBooks={() => {
                    onClickAction({
                      type: "remove",
                      package: item,
                      classRoom: undefined,
                    });
                  }}
                  isClassBook={item.classBook}
                  isVoted={item.voted}
                  isUserPackage={item.userPackage}
                  packageIdentifier={item.package_identifier}
                  isPremium={props.isPremium}
                  isTeacher={props.isTeacher}
                  showMenu={props.showMenu}
                />
              )
            );
          })}
      </Flex>
    </>
  );

  return (
    <div ref={myWrapper} className="cards">
      <>
        <Flex customClass={"gap10 column"}>{cards}</Flex>
        <div style={{ height: "80px", width: "100%" }} ref={ref}></div>
      </>

      {resume?.type && (
        <Resume
          isTeacher={props.isTeacher}
          isPremium={props.isPremium}
          data={resume.package}
          t={props.t}
          onClickRead={onClickRead}
          onClickRequestCase={(item: PackagesMetada) =>
            onClickAction({
              type: "vote",
              package: item,
              classRoom: undefined,
            })
          }
          onClickAddToMyClass={onAddToMyClass}
          onClickRemoveFromMyBooks={(item: PackagesMetada) =>
            onClickAction({
              type: "remove",
              package: item,
              classRoom: undefined,
            })
          }
          onClose={onCloseResume}
        />
      )}

      {action?.type && (
        <>
          <Popup
            accept={props.t(action.type === "classRoom" ? "add" : "accept")}
            cancel={props.t("cancel")}
            title={props.t(popupType())}
            subtitle={
              action.type === "classRoom" ? props.t("selectClasses") : ""
            }
            onClosePopup={onClosePopup}
            onClickAccept={onClickAccept}
            type={action.type}
          >
            {action.type === "classRoom" && (
              <Flex customClass={"border mt10 w100"}>
                <Flex customClass={"column room"}>
                  {action.classRoom?.map((item: IClassRoom) => {
                    return (
                      <ClassList guid={nanoid()} data={item} key={nanoid()} />
                    );
                  })}
                </Flex>
              </Flex>
            )}

            {action?.type === "vote" && (
              <Flex customClass={"mt20 column mb10 gap10"}>
                {props.t("requestCaseDescription")}
              </Flex>
            )}
          </Popup>
        </>
      )}
    </div>
  );
}
